@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.button-primary{
    @apply w-2/3 justify-center inline-flex items-center rounded-lg  border border-transparent bg-primary px-2.5 py-4 text-base font-medium text-white shadow-sm
}

.button-primary-small{
    @apply justify-center inline-flex items-center rounded-lg  border border-transparent bg-primary px-2.5 py-2 text-base font-medium text-white shadow-sm
}

.button-primary-outline{
    @apply justify-center inline-flex items-center rounded-lg border border-transparent border-primary px-2.5 py-2 text-base font-medium text-primary shadow-sm
}

.button-primary-outline-full{
    @apply w-2/3 justify-center inline-flex items-center rounded-lg border border-transparent border-primary px-2.5 py-4 text-base font-medium text-primary shadow-sm
}

/* .highlightClass{
    @apply bg-accent
} */